import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Row, Col, Container } from "reactstrap";

const QuizModal = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <> 
    <div>
        <a onClick={toggle} style={{cursor: 'pointer'}}>Take Quiz?</a>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle} style={{borderBottom:"none"}}></ModalHeader>
                <ModalBody style={{padding:"20px",textAlign:"center",fontFamily: "Times New Roman,serif",fontSize:"20px"}}>
                <Container>
                    <Row>
                        <Col md="12">
                            <div style={{backgroundColor:"#F0DBD9",padding:"10px",borderRadius:"8px",border:"1px dashed #CF8881"}}>
                                <h1 style={{fontFamily:"Poppins,sans-serif"}}>Take our Quiz?</h1>
                                <p className="bg-primary" style={{fontWeight:"600",fontSize:"14px",marginTop:"20px",paddingTop:"15px",paddingBottom:"15px",borderRadius:"5px"}}>1. Which country is known as the land of windmills?</p>
                                <div class="form-check form-check-inline" style={{fontFamily:"Poppins,sans-serif",fontSize:"15px"}}>
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                    <label class="form-check-label" for="inlineCheckbox1">Netherlands</label>
                                </div>
                                <div class="form-check form-check-inline" style={{fontFamily:"Poppins,sans-serif",fontSize:"15px"}}>
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                    <label class="form-check-label" for="inlineCheckbox2">Belgium</label>
                                </div>
                                <div class="form-check form-check-inline" style={{fontFamily:"Poppins,sans-serif",fontSize:"15px"}}>
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                    <label class="form-check-label" for="inlineCheckbox2">Austria</label>
                                </div>
                               
                                <p className="bg-primary" style={{fontWeight:"600",fontSize:"14px",marginTop:"20px",paddingTop:"15px",paddingBottom:"15px",borderRadius:"5px"}}>2. Which country is known as the land of windmills?</p>
                                <div class="form-check form-check-inline" style={{fontFamily:"Poppins,sans-serif",fontSize:"15px"}}>
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                    <label class="form-check-label" for="inlineCheckbox1">Netherlands</label>
                                </div>
                                <div class="form-check form-check-inline" style={{fontFamily:"Poppins,sans-serif",fontSize:"15px"}}>
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                    <label class="form-check-label" for="inlineCheckbox2">Belgium</label>
                                </div>
                                <div class="form-check form-check-inline" style={{fontFamily:"Poppins,sans-serif",fontSize:"15px"}}>
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                    <label class="form-check-label" for="inlineCheckbox2">Austria</label>
                                </div> 
                                <p className="bg-primary" style={{fontWeight:"600",fontSize:"14px",marginTop:"20px",paddingTop:"15px",paddingBottom:"15px",borderRadius:"5px"}}>3. Which country is known as the land of windmills?</p>
                                <div class="form-check form-check-inline" style={{fontFamily:"Poppins,sans-serif",fontSize:"15px"}}>
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                    <label class="form-check-label" for="inlineCheckbox1">Netherlands</label>
                                </div>
                                <div class="form-check form-check-inline" style={{fontFamily:"Poppins,sans-serif",fontSize:"15px"}}>
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                    <label class="form-check-label" for="inlineCheckbox2">Belgium</label>
                                </div>
                                <div class="form-check form-check-inline" style={{fontFamily:"Poppins,sans-serif",fontSize:"15px"}}>
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                    <label class="form-check-label" for="inlineCheckbox2">Austria</label>
                                </div>                                                                            
                            </div>
                        </Col>
                    </Row>
                 </Container>
                </ModalBody>
            </Modal>
        </div>       
    </>    
  );
}

export default QuizModal;