import React from 'react';
import daughter from '../assets/daughter-giving-gift-to-father.jpg';
import eventImage from '../assets/event-image.jpeg';

export default class SpeakersPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          speakers: []
        };
        this.responsive = {
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
          }
        };
    }
    componentDidMount = () => {
        this.getSpeakers();
        document.addEventListener('keydown', this.onKeyDown);
    }

    getSpeakers() {
        const dir = require.context('../assets/speakers', false, /\.(png|jpe?g|svg)$/)
        let images = [];
        dir.keys().forEach((item) => { images.push(dir(item).default); });
        this.setState({ speakers: images });
    }
    
    render() {
        return(
        <>
            <section className="banner" style={{paddingBottom:"30px", marginTop: 100, paddingTop: "0"}}>
                <div className="d-flex justify-content-center align-items-center" style={{backgroundImage: `url(${daughter})`,backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", paddingTop: '5%', paddingBottom: '5%'}}>
                    <img src={eventImage} style={{maxWidth: "50%",height: "auto"}} alt='Event'/>
                </div>
            </section>

            <section className="speaker" style={{paddingBottom:"60px"}}>
              <div className="wrapper">
                <div className="container">
                    <div className="row">
                        {this.state.speakers.map((speaker, index) => (
                          <div className="col-4 col-xs-4 col-sm-3" key={index}>
                            <img src={speaker} className="img-fluid" alt='speaker'/> 
                        </div>
                        ))}          
                    </div>  
                  </div>                      
              </div>
            </section>
        </>
        )
    }
}
