import React, { useState } from 'react';
import { Container, Row, Col, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import firebase from '@firebase/app';
import '@firebase/auth';
import * as googleAnalyticsService from '../service/googleAnalyticsService'
import * as utils from '../utils';

export default class ReferralsModal extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			apiResponse: null,
			apiResponseMessage: null
		};
	}

	signIn(method) {
		try {
			let provider;
			if (method === 'google') {
				provider = new firebase.auth.GoogleAuthProvider();
			} else if (method === 'facebook') {
				provider = new firebase.auth.FacebookAuthProvider();
			}
			firebase.auth().signInWithPopup(provider).then(data => {
				if (data) utils.setAuthUser(data.user);
				googleAnalyticsService.setUser(data.user)
				googleAnalyticsService.LogEvent({
					category: 'User Sign In',
					action: `Signed in with ${method}`,
					label: data.user.email
				})
				this.props.onClose();
				this.props.history.push({ pathname: this.props.redirect });
			}).catch(err => {
				if (err.message) {
					this.setState({
						apiResponse: 'error',
						apiResponseMessage: err.message
					})
				} else {
					this.setState({
						apiResponse: 'error',
						apiResponseMessage: 'Something went wrong.'
					})
				}
			});
		} catch (error) {
			this.setState({
				apiResponse: 'error',
				apiResponseMessage: error.message
			})
		}
	}

	componentDidMount() {
	}

	render() {
		return (
			<Modal isOpen={this.props.show ? true : false} show={this.props.show} size='md' toggle={this.props.onClose}>
				<ModalHeader className="text-center justify-content-center mb-4">{this.props.title}</ModalHeader>
				<ModalBody>
					<Container>
						{
							this.state.apiResponse && this.state.apiResponseMessage &&
							<Row>
								<Col xs={12}>
									<Alert color='danger' isOpen={true}>
										{this.state.apiResponseMessage}
									</Alert>
								</Col>
							</Row>
						}
						<Row className="justify-content-center">
							<Col xs={7}>
								<Button variant="primary" color='primary' className="py-3 w-100" onClick={() => { this.signIn('google') }} style={{ border: 'none' }}>
									<img src='/images/google.png' style={{ height: '20px', marginRight: '5px' }} alt='Google'></img>
									Sign In With Google
								</Button>
							</Col>
						</Row>
						<Row className="mt-3 mb-3 justify-content-center">
							<Col xs={7}>
								<Button variant="primary" color='primary' className="w-100 py-3" onClick={() => { this.signIn('facebook') }} style={{ border: 'none' }}>
									<img src='/images/facebook.png' style={{ height: '20px', marginRight: '5px' }} alt='Facebook'></img>
									Sign In With Facebook
								</Button>
							</Col>
						</Row>
					</Container>
				</ModalBody>
				<ModalFooter style={{ borderTop: 'none', justifyContent: 'center' }}>
				</ModalFooter>
			</Modal>
		);
	}
}
