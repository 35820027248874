import firebase from '@firebase/app';
import '@firebase/firestore';

export const setAuthUser = (user) => {
	localStorage.setItem('user', JSON.stringify(user));
}

export const getAuthUser = () => {
	let user = localStorage.getItem('user');
	if (user) return JSON.parse(localStorage.getItem('user'));
	return null;
	try {
		user = firebase.auth().currentUser;
		if (user) return JSON.parse(localStorage.getItem('user'));
		firebase.auth().onAuthStateChanged((userObj) => {
			if (userObj) user = userObj;
		});
		return JSON.parse(localStorage.getItem('user'));
	} catch (e) {

	}
}

export const removeAuthUser = () => {
	localStorage.removeItem('user');
}
