import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class ModalRegistration extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      apiResponse: null,
      apiResponseMessage: null
    };
  }

  handleClick(answer) {
    this.props.onClose();
    if (answer === 'yes') {
      window.open('https://hopin.com/events/strongertogether', '_blank');
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show ? true : false} size='md' toggle={this.props.onClose}>
          <ModalHeader style={{ borderBottom: "none" }}></ModalHeader>
          <ModalBody className="text-center" style={{ padding: "0px"}}>
            <h6>Have you attended Hopin before?</h6>
          </ModalBody>
          <ModalFooter style={{ borderTop: "none", justifyContent: "center" }}>
            <Button color="primary" onClick={(e) => { e.preventDefault(); this.handleClick('yes'); }} style={{ backgroundColor: "#CF8881", border: "none", width: "100px" }}>YES</Button>{' '}
            <Button color="secondary" onClick={(e) => { e.preventDefault(); this.handleClick('no'); }} style={{ backgroundColor: "#C5C5C5", border: "none", width: "100px" }}>NO</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
