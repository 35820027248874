import React from 'react';
import './App.css';
import { Route, Switch, useHistory } from 'react-router-dom';
import firebase from '@firebase/app';
import '@firebase/auth';
import Header from './components/Header';
import SideMenuBar from './components/SideMenuBar';
import HomePage from './views/HomePage';
import SpeakersPage from './views/SpeakersPage';
import RegistrationPage from './views/RegistrationPage';
import PodcastsPage from './views/PodcastsPage';
import PodcastDetails from './components/PodcastDetails';
import Footer from './components/Footer';


function App() {
  initFirebase();
  const history = useHistory();

  return (
    <>
      <Header history={history} />
      <SideMenuBar history={history} />
      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/speakers" component={SpeakersPage} />
        <Route path="/registration/:action?" component={RegistrationPage} />
        <Route path="/podcasts" component={PodcastsPage} />
        <Route path="/podcast/:_id/:episodeId?" component={PodcastDetails} />
      </Switch>
      <Footer />
    </>

  );
}

const initFirebase = () => {
  if (firebase.apps.length === 0) {
    var firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };
    firebase.initializeApp(firebaseConfig);
  }
}

export default App;
