import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, Container, Button } from 'reactstrap';
import * as googleAnalyticsService from '../service/googleAnalyticsService'
import {
	FacebookShareButton, FacebookIcon, WhatsappShareButton,
	WhatsappIcon, TelegramShareButton, TelegramIcon,
	FacebookMessengerShareButton, FacebookMessengerIcon, TwitterShareButton,
	TwitterIcon, EmailShareButton, EmailIcon
} from 'react-share';

export default class SocialShareModal extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			podcast: this.props.podcast,
			episode: this.props.episode
		};
	}

	logGoogleAnalyticsEvent(platform) {
		googleAnalyticsService.LogEvent({
			category: 'Share Episode',
			action: `User clicked on ${platform} share button`,
			label: `Episode ID: ${this.state.episode._id}`
		})
	}

	render() {
		return (
			<Modal isOpen={this.props.show ? true : false} size='md' toggle={this.props.onClose}>
				<ModalHeader>{this.state.episode.title.slice(0, 40)}</ModalHeader>
				<ModalBody>
					<Container>
						<Row><Col xs={12} style={{ marginBottom: '10px', fontWeight: 'bold' }}>Share Episode</Col></Row>
						<Row>
							<Col xs={2}>
								<WhatsappShareButton
									url={`${process.env.REACT_APP_URL}/podcast/${this.state.podcast._id}/${this.state.episode._id}`}
									title={`${process.env.REACT_APP_NAME} - ${this.props.episode.title}`}
									onClick={(e) => this.logGoogleAnalyticsEvent('WhatsApp')}
								>
									<WhatsappIcon size={32} round />
								</WhatsappShareButton>
							</Col>
							<Col xs={2}>
								<TelegramShareButton
									url={`${process.env.REACT_APP_URL}/podcast/${this.state.podcast._id}/${this.state.episode._id}`}
									title={`${process.env.REACT_APP_NAME} - ${this.props.episode.title}`}
									onClick={(e) => this.logGoogleAnalyticsEvent('Telegram')}
								>
									<TelegramIcon size={32} round />
								</TelegramShareButton>
							</Col>
							<Col xs={2}>
								<FacebookShareButton
									url={`${process.env.REACT_APP_URL}/podcast/${this.state.podcast._id}/${this.state.episode._id}`}
									quote={`${process.env.REACT_APP_NAME} - ${this.props.episode.title}`}
									description={`${this.props.podcast.description}`}
									className="Demo__some-network__share-button"
									onClick={(e) => this.logGoogleAnalyticsEvent('Facebook')}
								>
									<FacebookIcon size={32} round />
								</FacebookShareButton>
							</Col>
							<Col xs={2}>
								<FacebookMessengerShareButton
									url={`${process.env.REACT_APP_URL}/podcast/${this.state.podcast._id}/${this.state.episode._id}`}
									quote={`${process.env.REACT_APP_NAME} - ${this.props.episode.title}`}
									description={`${this.props.podcast.description}`}
									className="Demo__some-network__share-button"
									onClick={(e) => this.logGoogleAnalyticsEvent('Facebook Messenger')}
								>
									<FacebookMessengerIcon size={32} round />
								</FacebookMessengerShareButton>
							</Col>
							<Col xs={2}>
								<TwitterShareButton
									url={`${process.env.REACT_APP_URL}/podcast/${this.state.podcast._id}/${this.state.episode._id}`}
									title={`${process.env.REACT_APP_NAME} - ${this.props.episode.title}`}
									onClick={(e) => this.logGoogleAnalyticsEvent('Twitter')}
								>
									<TwitterIcon size={32} round />
								</TwitterShareButton>
							</Col>
							<Col xs={2}>
								<EmailShareButton
									body={`${process.env.REACT_APP_URL}/podcast/${this.state.podcast._id}/${this.state.episode._id}`}
									subject={`${process.env.REACT_APP_NAME} - ${this.props.episode.title}`}
									onClick={(e) => this.logGoogleAnalyticsEvent('Email')}
								>
									<EmailIcon size={32} round />
								</EmailShareButton>
							</Col>
						</Row>
					</Container>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={this.props.onClose}>Cancel</Button>
				</ModalFooter>
			</Modal>
		)
	}
}
