import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { InputGroup, Spinner, Input, Alert } from 'reactstrap';
import { Button } from 'reactstrap';
import * as EmailValidator from 'email-validator';
import background from '../assets/bg-image.jpg';
import ModalRegistration from '../components/ModalRegistration';
import QuizModal from '../components/QuizModal';
import '../assets/custom.css';
import '../assets/responsive.css';

export default class RegistrationPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedTab: this.props.match.params.action || 'welcome',
            showPromtModal: false,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            formSuccessErrorMessage: null,
            messageType: null,
            loading: false
        }
        this.now = Date.now();
    }

    onChange(key, value) {
        let formSuccessErrorMessage = null;
        if (key === 'phone') {
            const regex = /^(0+|[1-9]\d*)$/;
            if (value && !regex.test(value)) return;
        } else if (!value.length) {
            if (key === 'firstName') formSuccessErrorMessage = 'Please Enter First Name.';
            if (key === 'lastName') formSuccessErrorMessage = 'Please Enter Last Name.';
            if (key === 'email') formSuccessErrorMessage = 'Please Enter Email.';
        }
        if (key === 'email' && !EmailValidator.validate(value)) formSuccessErrorMessage = 'Please Enter Valid Email.';
        let obj = { [key]: value };
        this.setState(obj);
        this.setState({
            formSuccessErrorMessage: formSuccessErrorMessage,
            messageType: formSuccessErrorMessage ? 'error' : null
        });
    }

    onSubmit = async (event) => {
        try {
            this.setState({ loading: true });
            var payload = {
                eventID: 'FathersDaySummit2021',
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                emailID: this.state.email,
                contactNumber: this.state.phone
            };
            const formBody = JSON.stringify(payload, (key, value) => {
                if (value !== null) return value;
                return {};
            });
            let url = `${process.env.REACT_APP_FIREBASE_API_END_POINT}/events/register`;
            fetch(url, {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: formBody,
            }).then((data) => {
                if (data.status === 403) throw new Error('Something went wrong, Please try after sometime.');
                this.setState({
                    formSuccessErrorMessage: 'Registration Successful',
                    messageType: 'success',
                    showPromtModal: true,
                    loading: false
                });
            }).catch((error) => {
                this.setState({
                    formSuccessErrorMessage: error.message,
                    messageType: 'error',
                    loading: false
                });
                return error;
            });
        } catch (error) {
            this.setState({
                formSuccessErrorMessage: error.message,
                messageType: 'error',
                loading: false
            });
        }
    }

    render() {
        const disabled = (
            this.state.firstName &&
            this.state.lastName &&
            this.state.email
        );
        return (
            <>
                <section className='banner' style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: 'auto', paddingBottom: '180px' }}>
                    <Container>
                        <Container>
                            <Row>
                                <Col sm='12' md={{ size: 8, offset: 2 }} >
                                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', textAlign: 'center', boxShadow: 'rgb(191 179 179) 0px 0px 6px', borderRadius: '10px', height: '60px' }}>
                                        <Button onClick={() => this.setState({ selectedTab: 'welcome' })} className="welcome" style={{color: (this.state.selectedTab === 'welcome' ? '#000000' : '#8a7e7e'), boxShadow: 'none', fontWeight: '500', textDecoration: (this.state.selectedTab === 'welcome' ? 'underline' : 'none'), textUnderlineOffset: '0.5em' }}>Welcome</Button>
                                        <Button onClick={() => this.setState({ selectedTab: 'steps' })} className="how-to-register" style={{color: (this.state.selectedTab === 'steps' ? '#000000' : '#8a7e7e'), boxShadow: 'none', fontWeight: '500', textDecoration: (this.state.selectedTab === 'steps' ? 'underline' : 'none'), textUnderlineOffset: '0.5em' }}>How to register</Button>
                                        <Button onClick={() => this.setState({ selectedTab: 'register' })} className="register" style={{color: (this.state.selectedTab === 'register' ? '#000000' : '#8a7e7e'), boxShadow: 'none', fontWeight: '500', textDecoration: (this.state.selectedTab === 'register' ? 'underline' : 'none'), textUnderlineOffset: '0.5em' }}>Register</Button>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ display: this.state.selectedTab == 'welcome' ? 'block' : 'none' }}>
                                <Row>
                                    <Col sm='12' md={{ size: 8, offset: 2 }} style={{ }} className="welcome-outer px-5">
                                        <p style={{ lineHeight: '28px' }}>
                                            We brought together thousands of women from around the globe for International Women’s Day.
                                            We have learned from mothers around the world this Mother’s Day.
                                            And this June 22 to 24, in honor of this Father’s Day,
                                            we’re inviting you to join us for the Amplifying Her Voice Father’s Day “Stronger Together” Summit as we join forces
                                            with the fathers speaking up and fighting for gender equity, safety for all, full economic participation for everyone,
                                            and more—all while raising the next generation of empowered, ambitious, equality-focused young people.
                                        </p>
                                        <p style={{ lineHeight: '28px' }}>
                                            Hear from the dads in our lives about gender roles, supporting children’s gender expression, confidence, independence,
                                            the role of feminism in fatherhood and in men’s lives, the importance and challenge of being allies to girls and women,
                                            navigating masculinity, mental health, preventing violence, fulfillment as fathers, supporting their partners,
                                            encouraging girls (and children of any gender) to pursue their dreams, the experiences of sons and daughters, and more.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ display: this.state.selectedTab == 'steps' ? 'block' : 'none' }}>
                                <Row>
                                    <Col sm='12' md={{ size: 8, offset: 2 }} className="position-relative p-4 d-flex flex-column align-items-center justify-content-center"
                                        style={{ backgroundColor: 'rgb(255 255 255 / 60%)', boxShadow: '0 0 6px #bfb3b3', top: '30px', borderRadius: '20px'}}>
                                        <video style={{ maxWidth: '100%', height: 'auto', maxHeight: '450px', marginTop: '10px', marginBottom: '10px'}} controls>
                                            <source src='/videos/AHV_How_To_Register.mp4' type='video/mp4' />
                                            Your browser does not support the video tag.
                                        </video>
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                                            <QuizModal />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ display: this.state.selectedTab == 'register' ? 'block' : 'none' }}>
                                <Row>
                                    <Col sm='12' md={{ size: 8, offset: 2 }} style={{ }} className="register-outer">
                                        <h1 className='session-heading text-center' style={{color: '#000'}}>Amplifying Her Voice Registration</h1>
                                        <div style={{ maxWidth: '450px', height: '70px', position: 'relative', top: '20%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                            {
                                                this.state.formSuccessErrorMessage &&
                                                <Col xs={12}>
                                                    <Alert color={(this.state.messageType === 'error') ? 'danger' : 'success'} isOpen={true} style={{ fontSize: '15px' }}>
                                                        {this.state.formSuccessErrorMessage}
                                                    </Alert>
                                                </Col>
                                            }
                                            <InputGroup style={{ height: '60px' }}>
                                                <Input
                                                    placeholder='First Name'
                                                    type='text'
                                                    name={'firstName-' + this.now}
                                                    id={'firstName-' + this.now}
                                                    value={this.state.firstName}
                                                    onChange={(e) => this.onChange('firstName', e.target.value)}
                                                    style={{ backgroundColor: '#F0DBD9', border: 'none', border: '1px solid #CF8881' }}
                                                />
                                            </InputGroup>
                                            <InputGroup style={{ marginTop: '20px', height: '60px' }}>
                                                <Input
                                                    placeholder='Last name'
                                                    type='text'
                                                    name={'lastName-' + this.now}
                                                    id={'lastName-' + this.now}
                                                    value={this.state.lastName}
                                                    onChange={(e) => this.onChange('lastName', e.target.value)}
                                                    style={{ backgroundColor: '#F0DBD9', border: 'none', border: '1px solid #CF8881' }}
                                                />
                                            </InputGroup>
                                            <InputGroup style={{ marginTop: '20px', height: '60px' }}>
                                                <Input
                                                    placeholder='Email'
                                                    type='text'
                                                    name={'email-' + this.now}
                                                    id={'email-' + this.now}
                                                    value={this.state.email}
                                                    onChange={(e) => this.onChange('email', e.target.value)}
                                                    style={{ backgroundColor: '#F0DBD9', border: 'none', border: '1px solid #CF8881' }}
                                                />
                                            </InputGroup>
                                            <InputGroup style={{ marginTop: '20px', height: '60px' }}>
                                                <Input
                                                    placeholder='Contact Number (optional)'
                                                    type='text'
                                                    name={'phone-' + this.now}
                                                    id={'phone-' + this.now}
                                                    value={this.state.phone}
                                                    onChange={(e) => this.onChange('phone', e.target.value)}
                                                    style={{ backgroundColor: '#F0DBD9', border: 'none', border: '1px solid #CF8881' }}
                                                />
                                            </InputGroup>
                                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                {
                                                    !this.state.loading &&
                                                    <Button
                                                        color='primary'
                                                        onClick={this.onSubmit}
                                                        disabled={!disabled}
                                                        type='submit'
                                                        style={{ border: 'none', width: '120px', height: '40px', boxShadow: 'rgb(191 179 179) 0px 0px 6px' }}
                                                    >
                                                        REGISTER
                                                    </Button>
                                                }
                                                {
                                                    this.state.loading &&
                                                    <Spinner size='sm' className="text-primary" style={{ width: '2rem', height: '2rem' }}><span className='sr-only'></span></Spinner>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Container>
                </section>
                {
                    this.state.showPromtModal &&
                    <ModalRegistration
                        show={this.state.showPromtModal ? 1 : 0}
                        history={this.props.history}
                        onClose={() => {
                            this.setState({ showPromtModal: 0 });
                        }}
                    />
                }
            </>
        )
    }
}
