import React from 'react';
import { Row, Col, Container, Media } from 'reactstrap';
import { BsStar, BsFillHeartFill, BsHeart } from 'react-icons/bs';
import { BiShareAlt } from "react-icons/bi";
import { HiOutlinePlay, HiOutlinePause } from "react-icons/hi";
import firebase from '@firebase/app';
import '@firebase/firestore';
import { episodeService } from '../service/episodeService';
import SocialShareModal from '../components/SocialShareModal';
import * as utils from '../utils';
import * as googleAnalyticsService from '../service/googleAnalyticsService'
import '../index.css';

export default class PodcastDetails extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			podcast: this.props.podcast,
			episodes: [],
			user: null,
			userProfile: null,
			isFollowing: false,
			selectedEpisode: null,
			openSocialShareModal: false,
			episodeToShare: null
		};
		this._id = this.props.match.params._id;
		this.episodeId = null;
		if (this.props.match.params.episodeId) this.episodeId = this.props.match.params.episodeId;
	}

	componentDidMount() {
		const user = utils.getAuthUser();
		if (!user) {
			this.props.history.push('/');
			return;
		}
		this.setState({ user: user }, () => {
			this.getUserProfile();
			this.getPodcast();
		});
		googleAnalyticsService.PageView(`/podcast/${this._id}`)
	}

	componentWillUnmount() {
	}

	getUserProfile() {
		const fireStore = firebase.firestore();
		fireStore.collection('users').doc(this.state.user.uid).get().then((snapshot) => {
			const userProfile = snapshot.data();
			this.setState({ userProfile: userProfile });
		});
	}

	isEpisodeLiked(episode) {
		if (!this.state.userProfile) return false;
		return this.state.userProfile.likes.some(obj => obj.episodeId === episode._id);
	}

	logGoogleAnalyticsEvent() {
		googleAnalyticsService.LogEvent({
			category: 'See Podcast Details',
			action: `User visited on podcast details page`,
			label: `Episode ID: ${this.state.podcast._id}`
		})
	}

	async getPodcast() {
		const fireStore = firebase.firestore();
		fireStore.collection('podcasts').doc(this._id).get().then((doc) => {
			let podcast = doc.data();
			podcast._id = doc.id;
			this.setState({ podcast: podcast }, () => {
				this.logGoogleAnalyticsEvent()
			})
			fireStore.collection('podcasts').doc(podcast._id).collection('episodes').orderBy('lastBuildDate')
				.get().then((epiData) => {
					const episodeArray = [];
					let selectedEpisode = null;
					epiData.docs.forEach(element => {
						const episodeData = element.data();
						episodeData._id = element.id;
						episodeArray.push(episodeData);
						if (this.episodeId && this.episodeId === episodeData._id) {
							selectedEpisode = episodeData;
						}
					});
					this.setState({ episodes: episodeArray, selectedEpisode: selectedEpisode });
					fireStore.collection('users').doc(this.state.user.uid).get().then((snapshot) => {
						const userData = snapshot.data();
						const following = userData.following.filter(
							(l) => l.podcastId === podcast._id
						)
						this.setState({ isFollowing: following.length > 0 });
					});
				});
		});
	}

	getEpisode(_id) {
		const fireStore = firebase.firestore();
		fireStore.collection('podcasts').doc(this.state.podcast._id).collection('episodes').doc(_id).get()
			.then((episodeData) => {
				const data = episodeData.data();
				let episodes = this.state.episodes;
				var episodeIndex = episodes.findIndex(e => e._id == data._id);
				episodes[episodeIndex] = data;
				this.setState({ episodes: episodes });
			});
	}

	handleSelectAndPlayEpisode(episode) {
		this.setState({ selectedEpisode: episode });
		episodeService.setEpisode(episode);
	}

	handleFollowToggle() {
		let podcast = this.state.podcast;
		if (!this.state.isFollowing) {
			podcast.followers += 1;
		} else {
			if (podcast.followers > 0) podcast.followers -= 1;
		}
		this.setState({ podcast: podcast });
		this.setState({ isFollowing: !this.state.isFollowing });
		var payload = {
			'podcastId': podcast._id,
			'followers': podcast.followers,
			'followed': !this.state.isFollowing
		};
		const user = firebase.auth().currentUser;
		const formBody = JSON.stringify(payload, (key, value) => {
			if (value !== null) return value;
			return {};
		});
		user.getIdTokenResult(true).then(data => {
			let url = `${process.env.REACT_APP_FIREBASE_API_END_POINT}/podcasts/follow/`
			fetch(url, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${data.token}`,
				},
				method: 'PUT',
				body: formBody,
			}).then(() => {
			}).catch((error) => {
				const e = {
					message: `Error: ${error}`,
				};
				return e;
			});
		});
	}

	handleLikeUnlike = (episode) => {
		const isLiked = this.isEpisodeLiked(episode);
		let userProfile = this.state.userProfile;
		if (!isLiked) {
			episode.likes += 1;
			let likeObj = {
				episodeId: episode._id,
				podcastId: this.state.podcast._id
			}
			userProfile.likes.push(likeObj);
		} else {
			if (episode.likes > 0) episode.likes -= 1;
			const objIndex = userProfile.likes.findIndex(like => like.episodeId == episode._id);
			if (objIndex > -1) userProfile.likes.splice(objIndex, 1)
		}
		this.setState({ userProfile: userProfile });
		let episodes = this.state.episodes;
		var episodeIndex = episodes.findIndex(e => e._id == episode._id);
		episodes[episodeIndex] = episode;
		this.setState({ episodes: episodes });
		var payload = {
			'podcastId': this.state.podcast._id,
			'episodeId': episode._id,
			'likes': episode.likes,
			'liked': !isLiked
		};
		const user = firebase.auth().currentUser;
		const formBody = JSON.stringify(payload, (key, value) => {
			if (value !== null) return value;
			return {};
		});
		user.getIdTokenResult(true).then(data => {
			fetch(`${process.env.REACT_APP_FIREBASE_API_END_POINT}/podcasts/like/`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${data.token}`,
				},
				method: 'PUT',
				body: formBody,
			}).then(() => {
				this.getUserProfile();
				this.getEpisode(episode._id);
			}).catch((error) => {
				const e = {
					message: `Error: ${error}`,
				};
				return e;
			});
		});
	}


	handleShare(episode) {
		this.setState({
			openSocialShareModal: true,
			episodeToShare: episode
		})
	}

	render() {
		return (
			<>
				{
					this.state.podcast && this.state.podcast._id &&
					<>
						<section className='podcast-banner' style={{ paddingBottom: '20px', paddingTop: 110 }}>
							<Container>
								<Row>
									<Col sm='12' md={{ size: 4, offset: 4 }} style={{}}>
										<div style={{ textAlign: 'center' }}>
											<img src={this.state.podcast.imageURL} className='img-fluid' style={{ width: '617px', border: 'solid 10px #e8e7e7', boxShadow: '#bfb3b3 0px 0px 6px' }} alt={this.state.podcast.tittle} />
										</div>
									</Col>
								</Row>
								<Row style={{ marginTop: '30px' }}>
									<Col sm='12' md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }} style={{}}>
										<Row>
											<Col sm='12' md={{ size: 7 }}>
												<div className="justify-content-around align-items-center p-3 bg-white d-flex shadow mt-2" style={{ borderRadius: '10px' }}>
													<div className="px-3 text-center text-primary" style={{ fontWeight: '600' }}>{this.state.podcast.followers}<br />Followers</div>
													<div className="px-3 text-center text-primary" style={{ fontWeight: '600' }}>{this.state.episodes.length ? this.state.episodes.length : '0'}<br />Episodes</div>
												</div>
											</Col>
											<Col sm='12' md={{ size: 5 }} style={{}} style={{ textAlign: 'center' }}>
												<div className="justify-content-around align-items-center p-3 bg-white d-flex shadow mt-2" style={{ borderRadius: '10px' }}>
													<div className="text-center text-primary" style={{ fontWeight: '600' }}>
														<span onClick={(e) => { e.preventDefault(); this.handleFollowToggle(); }} style={{ marginLeft: '3px', fontWeight: 'bold', lineHeight: '45px' }}>
															<BsStar /> {this.state.isFollowing ? 'Following' : 'Follow'}
														</span>
													</div>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="mt-5 mb-3">
									<Col sm='12' md='12' lg={{ size: 6, offset: 3 }} style={{}}>
										<h1 className='newletter'>{this.state.podcast.title}</h1>
										<div className='newletter-text'>{this.state.podcast.description}</div>
									</Col>
								</Row>
							</Container>
						</section>

						<section className='podcasts' style={{ paddingBottom: '60px' }}>
							<Container>
								<Row>
									{this.state.episodes.map((episode, index) => (
										<Col xs={12} sm={12} md={6} lg={4} key={index} style={{ marginBottom: '30px' }}>
											<div className="shadow p-3 h-100" style={{ backgroundColor: this.state.selectedEpisode && this.state.selectedEpisode._id == episode._id ? '#CF8881' : '#FFF' }}>
												<div className="mb-4" style={{ fontWeight: '600' }}>
													{(episode.title.length < 35) ?
														episode.title.length :
														`${episode.title.slice(0, 35)}...`
													}
												</div>
												<div className="d-flex justify-content-between align-items-center">
													<div>
														<BiShareAlt onClick={(e) => { e.preventDefault(); this.handleShare(episode); }} size={25} style={{ color: '#000000', cursor: 'pointer' }} />

														{
															this.isEpisodeLiked(episode) ?
																<BsFillHeartFill onClick={(e) => { e.preventDefault(); this.handleLikeUnlike(episode) }} size={25} style={{ color: '#D7443E', cursor: 'pointer' }} />
																:
																<BsHeart onClick={(e) => { e.preventDefault(); this.handleLikeUnlike(episode) }} size={25} style={{ marginLeft: '20px', cursor: 'pointer' }} />
														}&nbsp;&nbsp;
														{`${episode.likes || 0} ${episode.likes < 2 ? 'Like' : 'Likes'}`}
													</div>
													{
														this.state.selectedEpisode?._id != episode._id &&
														<div className="ml-2">
															<HiOutlinePlay onClick={(e) => { this.handleSelectAndPlayEpisode(episode) }} size={40} style={{ cursor: 'pointer' }} />
															<HiOutlinePause onClick={(e) => { this.handleSelectAndPlayEpisode(episode) }} size={40} style={{ cursor: 'pointer' }} />
														</div>
													}
												</div>
											</div>
										</Col>
									))}
								</Row>
							</Container>
						</section>
						{
							this.state.openSocialShareModal &&
							<SocialShareModal
								show={this.state.openSocialShareModal ? 1 : 0}
								podcast={this.state.podcast}
								episode={this.state.episodeToShare}
								history={this.props.history}
								onClose={() => {
									this.setState({ openSocialShareModal: 0 });
								}}
							/>
						}
					</>
				}
			</>
		)
	}
}
