import { render } from '@testing-library/react';
import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import firebase from '@firebase/app';
import '@firebase/auth';
import SignInModal from './SignInModal';
import * as googleAnalyticsService from '../service/googleAnalyticsService'
import * as utils from '../utils';

export default class Header extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showSignInModal: false,
      user: null,
      isOpen: false
    };
  }


  componentDidMount() {
    const user = utils.getAuthUser();
    this.setState({ user: user });
  }

  componentDidUpdate() {
    if (this.state.user) return;
    const user = utils.getAuthUser();
    if (!user) return;
    this.setState({ user: user });
  }

  handleLogout() {
    firebase.auth().signOut();
    utils.removeAuthUser();
    this.setState({ user: null });
    this.props.history.push({ pathname: '/' });
    googleAnalyticsService.LogEvent({
      category: 'User Log Out',
      action: `User clicked on log out button`,
      label: this.state.user.email
    })
  }

  handleOpenSignInModal() {
    this.setState({ showSignInModal: true });
  }

  render() {
    return (
      <>
        <div className='navigation-bg'>
          <Navbar expand="md" className='navbar navbar-expand-lg navbar-light'>
            <div className='container-fluid px-4'>
              <NavbarBrand href="/">
                <img
                  src='/images/amplifying-logo-small.png'
                  className='img-fluid' alt='logo'
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.history.push({ pathname: '/' })}
                />
              </NavbarBrand>
              <NavbarToggler onClick={(e) => { this.setState({ isOpen: !this.state.isOpen }) }} className='navbar-toggler' />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className={`justify-content-end mr-auto ${!this.state.isOpen ? 'navbar-collapse' : ''}`} navbar>
                  <NavItem>
                    <NavLink className='px-4' aria-current='page' href='http://thestateofwomen.com/'>FOUNDATION</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className='px-4' href='https://sheqonomi.com/'>STORE</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className='px-4' href='https://womensradiostation.com/'>RADIO</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className='px-4' href='https://thestateofwomen.com/watch/'>MEDIA</NavLink>
                  </NavItem>
                  {
                    this.state.user ?
                      <NavItem>
                        <NavLink className='px-4' style={!this.state.isOpen ? { marginLeft: '5rem', paddingRight: 0 } : {}} href='#' onClick={() => this.handleLogout()}>Log Out</NavLink>
                      </NavItem>
                      :
                      <NavItem>
                        <NavLink className='px-4' style={!this.state.isOpen ? { marginLeft: '5rem', paddingRight: 0 } : {}} href='#' onClick={() => this.handleOpenSignInModal()}>SIGN IN</NavLink>
                      </NavItem>
                  }
                </Nav>
              </Collapse>
            </div>
          </Navbar>
          <nav>
            <a href='#first'><i className='far fa-user'></i></a>
            <a href='#second'><i className='fas fa-briefcase'></i></a>
            <a href='#third'><i className='far fa-file'></i></a>
            <a href='#fourth'><i className='far fa-address-card'></i></a>
          </nav>
        </div>
        {
          !!this.state.showSignInModal &&
          <SignInModal
            show={this.state.showSignInModal ? 1 : 0}
            title={'Sign In'}
            redirect={''}
            history={this.props.history}
            onClose={() => {
              this.setState({ showSignInModal: 0 });
            }}
          />
        }
      </>
    )
  }
}
