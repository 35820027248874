import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import home from '../assets/home.svg';
import podcasts from '../assets/podcasts.svg';
import session from '../assets/session.svg';
import video from '../assets/video.svg';
import playButton from '../assets/play-button.svg'
import pauseButton from '../assets/pause-button.svg'
import { episodeService } from '../service/episodeService'
import * as googleAnalyticsService from '../service/googleAnalyticsService'

export default class SideMenuBar extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			episode: null,
			isEpisodePlaying: false,
			isEpisodeDetailsPopoverOpen: false,
			isAudioPlayerPopoverOpen: false
		};
		this.player = createRef()
	}

	componentDidMount() {
		episodeService.getEpisode().subscribe(data => {
			this.setState({ episode: data.episode, isEpisodePlaying: true, isAudioPlayerPopoverOpen: true })
			googleAnalyticsService.LogEvent({
				category: 'Play Episode',
				action: `User clicked on play episode button`,
				label: `Episode ID: ${data.episode._id}`
			})
		})
	}

	render() {
		return (
			<>
				<SideNav onSelect={(selected) => {
					if (selected == 'podcasts') this.props.history.push({ pathname: '/podcasts' })
					else if (selected == 'sessions') window.open('https://www.youtube.com/channel/UC37qGlciyAIJ9KC4vb54E-g/videos')
					else this.props.history.push({ pathname: '/' })
				}}
				>
					<SideNav.Nav defaultSelected='home'>
						<NavItem eventKey='#'>
							<NavIcon><img src={home} style={{ width: '25px' }} alt="Home"/></NavIcon>
							<NavText style={{ color: '#682B55' }}>HOME</NavText>
						</NavItem>
						<NavItem eventKey='podcasts'>
							<NavIcon><img src={podcasts} style={{ width: '35px' }} alt="Podcasts"/></NavIcon>
							<NavText style={{ color: '#682B55' }}>PODCAST</NavText>
						</NavItem>
						<NavItem eventKey='sessions'>
							<NavIcon><img src={video} style={{ width: '30px' }} alt="Sessions"/></NavIcon>
							<NavText style={{ color: '#682B55' }}>EVENT VIDEOS</NavText>
						</NavItem>
						{

							<NavItem eventKey="player" className="media" style={{
								bottom: 0, left: 0, background: '#d8a69f', borderRadius: 0,
								display: (this.state.episode && this.state.episode.mediaURL ? 'block' : 'none')
							}}
								onClick={(e) => {
									if (this.state.isEpisodePlaying) this.player.current.audio.current.pause()
									if (!this.state.isEpisodePlaying) this.player.current.audio.current.play()
									this.setState({ isEpisodePlaying: !this.state.isEpisodePlaying })
								}}
							>
								<NavIcon className="media-icons">
									{this.state.isEpisodePlaying && <img src={pauseButton} style={{ width: '30px' }} alt="Pause"/>}
									{!this.state.isEpisodePlaying && <img src={playButton} style={{ width: '30px' }} alt="Play"/>}
								</NavIcon>
								<NavItem className="mt-0 mb-0 border-0" style={{ width: 300, backgroundColor: '#dfb4ae' }} eventKey={(e) => { }} disabled>
									<NavText title="player">
										<AudioPlayer
											ref={this.player}
											autoPlay
											src={this.state.episode?.mediaURL}
											onPlay={(e) => { this.setState({ isEpisodePlaying: true }) }}
											onPause={(e) => { this.setState({ isEpisodePlaying: false }) }}
											customProgressBarSection={[
												RHAP_UI.CURRENT_TIME,
												RHAP_UI.PROGRESS_BAR,
												RHAP_UI.DURATION,
											]}
											customControlsSection={[
											]}
										/>
									</NavText>
								</NavItem>
								<NavItem className="mt-0 mb-0 border-0" style={{ width: 300, backgroundColor: '#dfb4ae' }} disabled>
									<NavText title="episodeDetails">
										<h6 className="mb-0" style={{ color: 'rgb(104, 43, 85)' }}>
											{(this.state.episode?.title.length < 20) ?
												this.state.episode?.title.length :
												`${this.state.episode?.title.slice(0, 20)}...`
											}
										</h6>
										<div>
											<span style={{ fontSize: '11px' }}>
												{(this.state.episode?.description.length < 20) ?
													this.state.episode?.description.length :
													`${this.state.episode?.description.slice(0, 20)}...`
												}
											</span>
										</div>
									</NavText>
								</NavItem>
							</NavItem>
						}
					</SideNav.Nav>
				</SideNav>
				
			</>
		);
	}
}