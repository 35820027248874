import React from 'react';
import { Container } from 'reactstrap';
import insta from '../assets/instagram.png';
import facebook from '../assets/facebook.png';
import twitter from '../assets/twitter.png';



function Footer() {
    return (
        <>
            <div className="container-fluid footer" style={{ backgroundColor: "#F0DBD9" }}>
                <div className="row">
                    <div className="col-12">
                        <div style={{ textAlign: "center", paddingBottom: "10px", paddingTop: "30px", backgroundColor: "#F0DBD9" }}>
                            <p>Copyright    2021 Amplifying Her Voice | All Rights Reserved</p>
                        </div>

                        <div style={{display:"",textAlign: "center", paddingBottom: "10px", backgroundColor: "#F0DBD9" }}>
                            <a href="https://www.instagram.com/sheqonomi/" target="_blank"><img src={insta} alt="Instagram" style={{width:"30px",margin:"8px"}}/></a>
                            <a href="https://www.facebook.com/sheqonomi" target="_blank"><img src={facebook} alt="Facebook" style={{width:"30px",margin:"8px"}}/></a>
                            <a href="https://twitter.com/sheqonomi/" target="_blank"><img src={twitter} alt="Twitter" style={{width:"30px",margin:"8px"}}/></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;