import ReactGA from 'react-ga'
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, { debug: true, redactEmail: true })

export const setUser = (user) => {
	try {
		ReactGA.set({ userId: user.uid })
	} catch (e) {
		console.log('Error in set user')
		console.log(e)
	}
}

export const LogEvent = (data) => {
	try {
		ReactGA.event(data)
	} catch (e) {
		console.log('Error in log event')
		console.log(e)
	}
}

export const PageView = (page) => {
	try {
		ReactGA.pageview(page);
	} catch (e) {
		console.log('Error in page view')
		console.log(e)
	}
}