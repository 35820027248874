import React from 'react';
import newsLetter from '../assets/newsletter.jpg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import firebase from '@firebase/app';
import '@firebase/firestore';
import daughter from '../assets/daughter-giving-gift-to-father.jpg';
import eventImage from '../assets/event-image.jpeg';
import download1 from '../assets/download-1.png';
import download2 from '../assets/download-2.png';
import download3 from '../assets/download-3.png';
import calender from '../assets/calendar.svg';
import download4 from '../assets/download-4.svg';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { Row, Col, Container, Card, CardText, CardBody, CardTitle, Button } from 'reactstrap';
import '../assets/custom.css';
import '../assets/responsive.css';
import * as utils from '../utils';
import SignInModal from '../components/SignInModal';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import imgDownlad from '../assets/Arrow-Down.png';

export default class HomePage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            podcasts: [],
            news: [],
            partners: [],
            speakers: [],
            selectedSession: 0,
            openSessionModal: false,
            showSignInModal: false
        };
        this.responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 5
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1
            }
        };
        this.eventImages = [
            '/schedule/AHVSchedule_StrongerTogether_Day1.jpg',
            '/schedule/AHVSchedule_StrongerTogether_Day2.jpg',
            '/schedule/AHVSchedule_StrongerTogether_Day3.jpg',
        ];
    }

    componentDidMount = () => {
        this.getNews();
        this.getFeaturedPodcasts();
        this.getPartners();
        this.getSpeakers();
        document.addEventListener('keydown', this.onKeyDown);
    }

    getNews() {
        const fireStoreDiscover = firebase.firestore();
        fireStoreDiscover.collection('discover').limit(25).get().then((doc) => {
            doc.forEach((record) => {
                const data = record.data();
                if (data && data.news.length) {
                    const newsData = data.news;
                    this.setState({ news: newsData });
                }
            })
        })
    }

    getFeaturedPodcasts() {
        const fireStorePodcasts = firebase.firestore();
        fireStorePodcasts.collection('podcasts').orderBy('score').limit(10).get().then((doc) => {
            const podcasts = [];
            doc.forEach((record) => {
                const data = record.data();
                data._id = record.id;
                if (data && data.processed) {
                    podcasts.push(data);
                }
            });
            this.setState({ podcasts: podcasts });
        })
    }

    getPartners() {
        const dir = require.context('../assets/partners', false, /\.(png|jpe?g|svg)$/)
        let images = [];
        dir.keys().forEach((item) => { images.push(dir(item).default); });
        this.setState({ partners: images });
    }

    getSpeakers() {
        const dir = require.context('../assets/speakers', false, /\.(png|jpe?g|svg)$/)
        let images = [];
        dir.keys().forEach((item) => { images.push(dir(item).default); });
        this.setState({ speakers: images });
    }

    openSessionModal(session) {
        this.setState({
            selectedSession: session,
            openSessionModal: true
        })
    }

    handlePodcastClick(podcast) {
        const user = utils.getAuthUser();
        if (!user) {
            this.setState({ showSignInModal: true });
            return;
        }
        this.props.history.push(`/podcast/${podcast._id}`);
    }

    render() {
        return (
            <>
                <section className='banner'>
                    <Container>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="7">
                                <div className="position-relative" style={{ backgroundImage: `url(${daughter})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '500px', backgroundPosition: 'center' }}>
                                    <img src={eventImage} alt='Event' style={{ position: 'relative', bottom: '-50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '90%', height: 'auto' }} />
                                    <Button className="register-button px-4" onClick={(e) => { e.preventDefault(); this.props.history.push('/registration/register'); }}>REGISTER NOW</Button>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="5">
                                <div className="position-relative mb-4">
                                    <a href="https://sheqonomi.com"><img src='/images/sheq_app_2.png' className='img-fluid' alt='logo' /></a>
                                    <Button className="btn-download position-absolute" style={{ right: 20, top: 20 }}>Download Now <img src={imgDownlad} /></Button>
                                </div>
                                <Row>
                                <Col xs="4" sm="4" md="4">
                                        <img
                                            src={download1}
                                            style={{ maxWidth: '100%', height: 'auto', marginTop: '0px', cursor: 'pointer' }}
                                            onClick={() => window.open('https://apps.apple.com/us/app/sheqonomi/id1543961477', '_blank')} alt='Download'/>
                                    </Col>
                                    <Col xs="4" sm="4" md="4">
                                        <img
                                            src={download2}
                                            style={{ maxWidth: '100%', height: 'auto', marginTop: '0px', cursor: 'pointer' }}
                                            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.sheqonomi', '_blank')} alt='Download'/>
                                    </Col>
                                    <Col xs="4" sm="4" md="4">
                                        <img
                                            src={download3}
                                            style={{ maxWidth: '100%', height: 'auto', marginTop: '0px', cursor: 'pointer' }}
                                            onClick={() => window.open('https://www.kaiostech.com/store/apps/?bundle_id=kaios.app.sheqonomiapp', '_blank')} alt='Download'/>
                                    </Col>
                                </Row>
                                <div style={{ marginTop: "30px" }}>
                                    <Carousel
                                        responsive={this.responsive}
                                        showDots={false}
                                        arrows={true}
                                        infinite={true}
                                        centerMode={true}
                                        autoPlay={false}
                                        itemClass="partners-carousel-item"
                                        sliderClass="partners-carousel-custom-slider"
                                    >
                                        {this.state.partners.map((partner, index) => (
                                            <img src={partner} className='img-fluid' alt='podcast-1.jpg' style={{ height: '60px' }} key={index} />
                                        ))}
                                    </Carousel>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='schedule'>
                    <Container>
                        <Row>
                            <h1 className='session-heading mb-4'>SCHEDULE</h1>
                            <Col sm="12" md="6" lg="4">
                                <Card className="shadow xs-mb-4 md-mb-4" style={{cursor: 'pointer' }} onClick={() => this.openSessionModal(0)}>
                                    <Row className="align-items-center">
                                        <Col md='4' sm='4' xs='4'>
                                            <div className="card-img-bg h-100">
                                                <img src='/images/lotus.png' className='img-fluid' alt='logo' />
                                            </div>
                                        </Col>
                                        <Col md='8' sm='8' xs='8' style={{paddingLeft: 0, paddingRight: 0, marginLeft: '-12px'}}>
                                            <CardBody className="position-relative d-flex justify-content-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                                <CardTitle className='session-card-title'>Nov 17th, 2021</CardTitle>
                                                <img src={calender} alt='Calender' className="calender" />
                                            </CardBody>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col sm="12" md="6" lg="4">
                                <Card className="shadow xs-mb-4 md-mb-4" style={{ cursor: 'pointer' }} onClick={() => this.openSessionModal(1)}>
                                    <Row className="align-items-center">
                                        <Col md='4' sm='4' xs='4'>
                                            <div className="card-img-bg h-100">
                                                <img src='/images/lotus.png' className='img-fluid' alt='logo' />
                                            </div>
                                        </Col>
                                        <Col md='8' sm='8' xs='8' style={{paddingLeft: 0, paddingRight: 0, marginLeft: '-12px'}}>
                                            <CardBody className="position-relative d-flex justify-content-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                                <CardTitle className='session-card-title'>Nov 18th, 2021</CardTitle>
                                                <img src={calender} className="calender" alt='Calender'/>
                                            </CardBody>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col sm="12" md="6" lg="4">
                                <Card className="shadow" style={{ cursor: 'pointer' }} onClick={() => this.openSessionModal(2)}>
                                    <Row className="align-items-center">
                                        <Col md='4' sm='4' xs='4' >
                                            <div className="card-img-bg h-100">
                                                <img src='/images/lotus.png' className='img-fluid' alt='logo' />
                                            </div>
                                        </Col>
                                        <Col md='8' sm='8' xs='8' style={{paddingLeft: 0, paddingRight: 0, marginLeft: '-12px'}}>
                                            <CardBody className="position-relative d-flex justify-content-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                                <CardTitle className='session-card-title'>Nov 19th, 2021</CardTitle>
                                                <img src={calender} className="calender" alt='Calender'/>
                                            </CardBody>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    {
                        this.state.openSessionModal &&
                        <Lightbox
                            mainSrc={this.eventImages[this.state.selectedSession]}
                            onCloseRequest={() =>
                                this.setState({
                                    openSessionModal: false
                                })
                            }
                            onCloseRequest={() => this.setState({ openSessionModal: false })}
                            clickOutsideToClose={true}
                            imagePadding={70}
                        />
                    }
                </section>

                <section className='speakers' style={{ backgroundColor: '#CF8881' }}>
                    <div className="wrapper">
                        <div className='container'>
                            <div className='row'>
                                <div className="mb-4 d-flex justify-content-between align-items-center">
                                    <h1 className='session-heading text-white'>SPEAKERS</h1>
                                    <div className='text-white' style={{ cursor: 'pointer', fontWeight: '400' }} onClick={() => this.props.history.push('/speakers')}>View All</div>
                                </div>
                                <Carousel responsive={this.responsive} showDots={true} arrows={true} autoPlay={false} infinite={true}>
                                    {this.state.speakers.map((speaker, index) => (
                                        <Card style={{ marginRight: '20px', background: 'none' }} key={index}>
                                            <img src={speaker} className='img-fluid' alt='podcast-1.jpg' />
                                        </Card>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    !!this.state.podcasts.length &&
                    <section className='featured-podcasts' style={{ backgroundColor: '#CF8881' }}>
                        <div className="wrapper">
                            <div className='container'>
                                <div className='row'>
                                    <h1 className='session-heading text-white mb-4'>FEATURED PODCASTS</h1>
                                    <Carousel responsive={this.responsive} showDots={true} arrows={true} autoPlay={false} infinite={true} partialVisible={false}>
                                        {this.state.podcasts.map((podcast, index) => (
                                            <Card onClick={(e) => { e.preventDefault(); this.handlePodcastClick(podcast); }} key={index} style={{ marginRight: '20px', background: 'none', cursor: 'pointer' }}>
                                                <img src={podcast.imageURL} className='img-fluid' alt='podcast-1.jpg' />
                                                <CardBody style={{ textAlign: 'center', height: '80px', background: '#fff' }}>
                                                    <CardTitle tag='h5'>{podcast.title}</CardTitle>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                {
                    !!this.state.news.length &&
                    <section className='news-section'>
                        <div className="wrapper">
                            <div className='container'>
                                <div className='row'>
                                    <h1 className='session-heading mb-4'>NEWS</h1>
                                    <Carousel responsive={this.responsive} showDots={true} arrows={true} infinite={true} autoPlay={false}>
                                        {
                                            this.state.news.map((currentNews, index) =>
                                                <Card className="shadow" key={index} style={{ marginRight: '20px', boxShadow: '0 0 6px #bfb3b3', height: '100%' }}>
                                                    <img src={currentNews.image} className='img-fluid' alt='podcast-1.jpg' />
                                                    <CardBody style={{ textAlign: 'center' }}>
                                                        <CardTitle className='news-card-heading'>
                                                            {currentNews.title}
                                                        </CardTitle>
                                                        <CardTitle tag='h5' style={{marginBottom: '0.8rem'}}>{currentNews.author}</CardTitle>
                                                        <CardText className='news-card-innertext' style={{ textAlign: 'justify' }}>
                                                            {
                                                                currentNews.description.length > 50
                                                                    ?
                                                                    <>
                                                                        {currentNews.description.slice(0, 100) + '... '}
                                                                        <a href={currentNews.url} target='_blank'>Read More</a>
                                                                    </>
                                                                    :
                                                                    currentNews.description
                                                            }
                                                        </CardText>
                                                    </CardBody>
                                                </Card>
                                            )
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                
                <section className='' style={{ paddingTop: '60px', maxHeight: '900px', paddingBottom: '0px', backgroundImage: `url(${newsLetter})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                    <div className="section-bottom">
                        <Container>
                            <Row>
                                <Col sm="12" xs="12" md={{ size: 10, offset: 1 }} className="text-center">
                                    <iframe
                                        id="scaled-frame"
                                        src="https://sheqonomi.kartra.com/page/YcY1"
                                        scrolling="no"
                                        style={{width: '100%'}}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>

                {
                    !!this.state.showSignInModal &&
                    <SignInModal
                        show={this.state.showSignInModal ? 1 : 0}
                        title={'Sign In to listen your favorite podcast'}
                        redirect={''}
                        history={this.props.history}
                        onClose={() => {
                            this.setState({ showSignInModal: 0 });
                        }}
                    />

                }
            </>
        )
    }
}