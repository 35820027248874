import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import firebase from '@firebase/app';
import '@firebase/firestore';
import SignInModal from '../components/SignInModal';
import * as utils from '../utils';
import * as googleAnalyticsService from '../service/googleAnalyticsService';

export default class PodcastsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showSignInModal: false,
      podcasts: []
    };
    this.selectedPodcastId = null;
  }

  componentDidMount() {
    const fireStorePodcast = firebase.firestore();
    fireStorePodcast.collection('podcasts').orderBy('lastBuildDate', 'desc').get().then((doc) => {
      const podcasts = [];
      doc.forEach((record) => {
        const data = record.data();
        data._id = record.id;
        if (data && data.processed) {
          podcasts.push(data);
        }
      });
      this.setState({ podcasts: podcasts });
    })
    document.addEventListener('keydown', this.onKeyDown);
    googleAnalyticsService.PageView(`/podcasts`)
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  handlePodcastClick(podcast) {
    const user = utils.getAuthUser();
    if (!user) {
      this.setState({ showSignInModal: true });
      return;
    }
    this.selectedPodcastId = podcast._id;
    this.props.history.push(`/podcast/${this.selectedPodcastId}`);
  }

  render() {
    return (
      <>
        <section className='podcast-list' style={{ paddingTop: 60, paddingBottom: 60 }}>
          <div className='container'>
            <div className='row'>
              <h1 className="session-heading mb-5 text-center text-black" style={{ paddingTop: '60px' }}>The State of Women Podcast Network</h1>
              {this.state.podcasts.map((podcast, index) => (
                <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3' key={index} style={{ marginBottom: '30px' }}>
                  <div onClick={(e) => { this.handlePodcastClick(podcast); }} className="h-100" style={{ cursor: 'pointer' }} >
                    <Card key={index} className="h-100 shadow">
                      <img src={podcast.imageURL} className='img-fluid' alt={podcast.tittle} />
                      <CardBody style={{ textAlign: 'center' }}>
                        <CardTitle tag='h5' style={{ marginBottom: 0 }}>{podcast.title}</CardTitle>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {
          !!this.state.showSignInModal &&
          <SignInModal
            show={this.state.showSignInModal ? 1 : 0}
            title={'Sign In to listen your favorite podcast'}
            redirect={`podcast/${this.selectedPodcastId}`}
            history={this.props.history}
            onClose={() => {
              this.setState({ showSignInModal: 0 });
            }}
          />
        }
      </>
    )
  }
}
